export const COLORS = {
  light: {
    primary: {
      main: '#37474F',
      dark:'#202020'
    },
    secondary: {
      main: '#388E3C',

    }
  },
  dark: {
    primary: {
      main: '#1D3744',
      light: '#388E71', 
      background: '#1E1E1E',
      table:"#192831",
      mainDark:"#12242e"
    },
    secondary: {

      main: '#3acb87'
    }
  }
};