import React, { useRef, useEffect, useState, useMemo } from "react";
import "./MapStyle.css";
import Map, { FullscreenControl, Marker } from "react-map-gl";
import Pin from "./Objects/Pin";
import MapPopup from "./Objects/MapPopup";
import LocationPin from "./Objects/LocationPin";
import { Box, Button, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../assets/constants/theme/theme";




const MapWrapper = ({ data, loading, setLoading, tabIndex, currentLocation }) => {
  const [seed, setSeed] = useState(1);
  const mapContainerRef = useRef(null);
  const [popupInfo, setPopupInfo] = useState(null);
  const { t } = useTranslation();


  useEffect(() => {
    setSeed(Math.random());
  }, [tabIndex])

  const checkIfPositionInViewport = (lat, lng) => {
    const bounds = mapContainerRef.current.getBounds();
    return bounds.contains([lng, lat]);
  };

  // const checkBusinessesInViewport = () => {
  //   const newBusinesses = data.results.filter((business) => {
  //     const lat = business.geometry.coordinates[1];
  //     const lng = business.geometry.coordinates[0];
  //     return checkIfPositionInViewport(lat, lng);
  //   });

  //   setBusinesses(newBusinesses);
  //   return newBusinesses;
  // };

  const pins = useMemo(() =>
    data?.results?.map((feature, index) => (
      <Marker
        key={`marker-${index}`}
        longitude={feature.geometry.coordinates[1]}
        latitude={feature.geometry.coordinates[0]}
        anchor="bottom"
        onClick={(e) => {
          // If we let the click event propagates to the map, it will immediately close the popup
          // with `closeOnClick: true`
          e.originalEvent.stopPropagation();
          setPopupInfo(feature);

        }}
      >
        <Pin street_access={feature.street_access} />
      </Marker>
    ))
  );





  if (loading) return (<Box sx={{
    minHeight: "70vh",
    display: "flex",
    justifiyContent: "center",
    alignItems: "center"
  }}><Spinner boxSize={24} color="white" thickness="6px" /></Box>)

  return (
    <>
      <Map
        key={seed}
        initialViewState={{
          longitude: currentLocation ? currentLocation[0] : -58.43,
          latitude: currentLocation ? currentLocation[1] : -34.61,
          zoom: 11,
        }}
        style={{
          width: "94vw",
          height: "68vh",
          borderRadius: "30px",
          marginTop: "3vh",
          padding: 0
        }}
        mapStyle="mapbox://styles/muvinai/cln3h6po106jt01qi9nxxgmgj"
        containerStyle={{
          height: '100vh',
          width: '100vw'
        }}
        ref={mapContainerRef}
      // onMove={(e) => {
      //   setBusinesses(geoJson.features);
      // }}
      >
        <FullscreenControl />
        {pins}

        {popupInfo && (
          <MapPopup currentLocation={currentLocation} info={popupInfo} onClose={() => setPopupInfo(null)} />
        )}
        {currentLocation && (
          <Marker
            latitude={currentLocation[1]}
            longitude={currentLocation[0]}
            anchor="bottom"
          >
            <LocationPin />
          </Marker>
     
        )}

      </Map>

    </>
  );
};

export default MapWrapper;
