import { Search2Icon } from "@chakra-ui/icons";
import { Box, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { setName } from "../../stores/data";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const Search = ({ busqueda, setBusqueda, setLoading }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const buscar = () => {
    dispatch(setName(busqueda));
    setLoading(true);
  };

  const setear = (e) => {
    setBusqueda(e.target.value);
  };

  const validar = (e) => {
    if (e.nativeEvent.key === "Enter") {
      buscar();
    }
  };

  return (
    <>
      <InputGroup sx={{ display: "flex" }}>
        {window.screen.width < 800 ? (
          <Input
            inputMode="search"
            onKeyDown={validar}
            placeholder={t('placeholder.search')}
            sx={{
              color: "#505050",
              backgroundColor: "#DFDFDF",
              borderRadius: "20px",
              textAlign: "center",
              height: "45px",
              _focus: { borderColor: "#c4c4c4", outline: "none" },
            }}
            name="inputBusqueda"
            onChange={setear}
            value={busqueda}
          />
        ) : (
          <Input
            inputMode="search"
            onKeyDown={validar}
            placeholder={t('placeholder.search')}
            sx={{
              color: "#505050",
              backgroundColor: "#DFDFDF",
              borderRadius: "20px",
              textAlign: "center",
              height: "45px",
              _focus: { borderColor: "#c4c4c4", outline: "none" },
            }}
            name="inputBusqueda"
            onChange={setear}
            value={busqueda}
          />
        )}

        <InputRightElement
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Search2Icon
            sx={{
              _hover:
                { cursor: "pointer" },
              color: "#5d5e5e"
            }}
            mt={1}
            onClick={buscar}
          />
        </InputRightElement>
      </InputGroup>
    </>
  );
};

export default Search;
