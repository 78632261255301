import { configureStore } from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import { combineReducers } from "redux";

//Reducers

import dataSlice from "./data";

const rootReducer = combineReducers({
  data: dataSlice,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      inmutableCheck: { warnAfter: 400 },
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER], //No se chequean estos tipos de acciones
        warnAfter: 400,
      },
    }),
});

export default store;
