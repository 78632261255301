import "./App.css";
import mapboxgl from "mapbox-gl";
import TabBar from "./components/TabBar";
import { ChakraProvider, theme } from "@chakra-ui/react";
import { Provider } from "react-redux";
import store from "./stores/store.js";
import Header from "./components/Header/Header";


mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

function App() {
  return (
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <Header />
        <TabBar />
        {/* <MapWrapper /> */}
      </ChakraProvider>
    </Provider>
  );
}

export default App;
