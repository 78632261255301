import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Box } from "@chakra-ui/react";
import { setFilter } from "../../stores/data";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";

const FilterBar = ({ data, valor, setValor, setLoading }) => {

  const [info, setInfo] = useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!info) {
      axios({
        url: `${process.env.REACT_APP_API}/stores`,
        method: 'GET',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'x-api-key': process.env.REACT_APP_API_KEY,
        }
      })
        .then((dat) => setInfo(dat.data))
    };
  }, [])

  const filtros = info?.results?.map(
    (filt) =>
      filt.categories[0].charAt(0).toUpperCase() +
      filt.categories[0].substring(1).toLowerCase()
  );

  const filtered = filtros?.reduce((acc, item) => {
    if (!acc.includes(item)) {
      acc.push(item);
    }
    return acc;
  }, []);

  const handleChange = (e) => {
    setValor(e.value.valor);
    dispatch(setFilter(e.value));
    setLoading(true);
  };

  const options = filtered?.map((a) => ({ value: a, label: a }));

  if (window.screen.width < 800) {
    return (
      <Box width="47vw" marginRight="2vw">
        <Select
          value={valor}
          onChange={handleChange}
          placeholder={t('placeholder.filter')}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderRadius: "20px",
              backgroundColor: "#DFDFDF",
              color: "#5d5e5e",
              padding: 0,
              margin: 0,
              ":hover": { cursor: "pointer" },
            }),
          }}
          options={options}

        />{" "}
      </Box>
    );
  } else {
    return (
      <Box width="20vw" marginLeft="2vw" marginRight="2vw">
        <Select
          value={valor}
          onChange={handleChange}
          placeholder={t('placeholder.filter')}

          styles={{

            control: (baseStyles, state) => ({
              ...baseStyles,
              borderRadius: "20px",
              backgroundColor: "#DFDFDF",
              color: "#5d5e5e",
              height: "45px",
              padding: 0,
              margin: 0,
              ":hover": { cursor: "pointer" },
            }),
          }}
          options={options}
        />{" "}
      </Box>
    );
  }
};

export default FilterBar;
