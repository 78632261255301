import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  filter: "",
  name: " ",
};

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setFilter: (state, action) => {
   
      state.filter = action.payload;
    },
    setName: (state, action) => {
    
      state.name = action.payload;
    },
  },
});

export const { setFilter, setName } = dataSlice.actions;

export default dataSlice.reducer;
