import { Box, Image, Link, Text } from "@chakra-ui/react";
import React from "react";
import { Popup } from "react-map-gl";
import mapIcon from "../../assets/constants/icons/map.svg";
import webIcon from "../../assets/constants/icons/web.svg";
import phoneIcon from "../../assets/constants/icons/phone.svg";
import mailIcon from "../../assets/constants/icons/mail.svg";
import directionIcon from "../../assets/constants/icons/direction.svg";
import { useTranslation } from "react-i18next";

const MapPopup = ({ info, onClose, currentLocation }) => {

  const category = info.categories[0];
  var distancia = Number(info.distance.toFixed(2));
  const { t} = useTranslation();
  return (
    <Popup
      anchor="top"
      longitude={Number(info.geometry.coordinates[1])}
      latitude={Number(info.geometry.coordinates[0])}
      onClose={onClose}
      closeButton={false}
      style={{ borderRadius: 12 }}
    >
      <Box
        margin={3}
        sx={{
          div: {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "clip",
          },
        }}
      >
        <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
          <Text
            style={{
              fontFamily: "GontserratSemibold",
              fontSize: 15,
              paddingBottom: 0,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "clip",
            }}
          >
            {info.title}
          </Text>   {currentLocation && <Text color="#707070" ml={2}>{distancia} km</Text>}</Box>
        <Text color="#707070" marginBottom={4} fontWeight={500} style={{

          paddingBottom: 0,
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "hidden",
        }}>
          {category[0].toUpperCase() + category.substring(1)}
        </Text>

        <Box display="flex">
          <Image src={mapIcon} marginRight={2} />{" "}
          <Text>{info.location.address}</Text>

        </Box>

        <Box
          sx={{
            display: "flex",
            width: "16vw",
            maxWidth: "200px",
            minWidth: "150px",
            justifyContent: "space-between",
            marginTop: "5px",
            a: {
              transition: "1s",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textColor: "white",
              _hover: {
                textColor: "black",
                textDecoration: "none",
              },
            },
          }}
        >
          <Link
            href={
              `https://www.google.com/maps/place/-34.6098641,-58.4318464/${info.geometry.coordinates[0]},${info.geometry.coordinates[1]}`}
            target="_blank"
          >
            <Text> {t('modal.go')}</Text> <Image boxSize={5} src={directionIcon} />
            {/* https://www.google.com/maps/dir/-34.6144314,-58.4059232 */}
            {/* falta cambiar el primer parametro para que sea la ubicacion de la persona */}
          </Link>
          <Link href={info.contact.phone}>
            <Text>{t('modal.call')}</Text> <Image boxSize={5} src={phoneIcon} />
          </Link>
          <Link href={info.contact.web} target="_blank">
            <Text> {t('modal.web')}</Text>
            <Image boxSize={5} src={webIcon} />
          </Link>
          <Link href={"mailto:" + info.contact.email}>
            <Text> {t('modal.mail')}</Text>
            <Image boxSize={5} src={mailIcon} />
          </Link>{" "}
        </Box>
      </Box>
    </Popup>
  );
};

export default MapPopup;
