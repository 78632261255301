import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Image,
  Link,
  Box,
  Text,
  Spinner,
} from "@chakra-ui/react";


import phone from "../../assets/constants/icons/phoneWhite.svg";
import web from "../../assets/constants/icons/webWhite.svg";
import mail from "../../assets/constants/icons/mailWhite.svg";
import { COLORS } from "../../assets/constants/theme/theme";
import map from "../../assets/constants/icons/mapWhite.svg"
import Map_fill from "../../assets/constants/icons/Map_fill.svg"
import { useCallback, useRef } from "react";
import { distance } from "framer-motion";
import { useTranslation } from "react-i18next";

const Tabla = ({ data, loading, setMoreData, moreData, currentLocation }) => {
  const observer = useRef();
  const { t} = useTranslation();



  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setMoreData(moreData++);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading]
  );

  if (!data) return (<Box sx={{
    minHeight: "70vh",
    display: "flex",
    justifiyContent: "center",
    alignItems: "center"
  }}><Spinner boxSize={24} color="white" thickness="6px" /></Box>)

  if (window.screen.width < 800) {
    return <>
      <Box sx={{
        overflow: "scroll",
        whiteSpace: "nowrap",
        textOverflow: "clip",
        height: "67vh"
        , marginTop: "20px"
      }}>
        <Box>
          {data.length == 0 ? <Box
            height="200px"
            alignItems="center"
            justifyContent="center"
            display="flex"
            color="white">There is no stores</Box> : null}
          {
            data.map((info, jota, jeta) => {
              var distancia = Number(info.distance.toFixed(2));
              const category = info.categories[0];
              return (
                <>
                  <Box sx={{
                    backgroundColor: COLORS.dark.primary.table,
                    borderRadius: 20,
                    width: "90vw",
                    height: "80px",
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "start"
                  }} key={info.id} >
                    <Box sx={{
                      height: "90%",
                      color: "white",
                      overflow: "scroll",
                      whiteSpace: "nowrap",
                      textOverflow: "clip",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      width: "70%"
                    }}>
                      {jota == jeta.length - 1 ? <Box ref={lastBookElementRef}></Box> : null}

                      <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "start",
                        marginLeft: "20px",
                        paddingTop: "10px"
                      }}>

                        <Text fontSize={16}>{info.title}</Text>
                        <Box display="flex"><Image src={map} boxSize={3} /> <Text fontSize={10}>{info.location.neighborhood}</Text>{ currentLocation && <Text ml={3} fontSize={10}>{distancia} km</Text>}</Box>
                        <Text fontSize={11}>{category[0].toUpperCase() + category.substring(1)}</Text>
                      </Box>
                    </Box>

                    <Box sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr);",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "20%",
                      height: "80%",
                      marginLeft: "10%",
                      marginTop: "3%",

                    }}>
                      <Link href={"mailto:" + info.contact.email} sx>
                        <Image src={mail} />
                      </Link>
                      <Link href={"sms:+" + info.contact.phone}>
                        <Image src={phone} />
                      </Link>
                      <Link href={info.contact.web} target="_blank">
                        <Image src={web} />
                      </Link>
                      <Link
                        href={currentLocation && `https://www.google.com/maps/place/${info.geometry.coordinates[0]},
                        ${info.geometry.coordinates[1]}`}
                        target="_blank"
                      >
                        <Image src={Map_fill} />
                      </Link>
                    </Box>
                  </Box>
                </>
              )
            })
          }
        </Box>

      </Box>

    </>
  } else {
    return (
      <>
        <Box sx={{
          overflow: "scroll",
          whiteSpace: "nowrap",
          textOverflow: "clip",
          height: "80vh",
        }}>
          <TableContainer width="100vw">
            <Table
              variant="striped"
              colorScheme="#065666"
              sx={{
                display: "flex",
                flexDirection: "column",
                Td: {
                  width: "15vw",
                  border: 0,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "clip",
                  paddingLeft: "3vw",
                  paddingRight: "3vw",
                  textAlign: "start",
                  fontSize: "80%",
                  a: {
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                  },
                },
                Th: {
                  width: "15vw",
                  color: "white",
                  border: "0",
                  fontFamily: "Roboto, sans-serif",
                  padding: "1vw",
                  paddingLeft: "3vw",
                  textAlign: "start",
                },
              }}
            >
              <TableCaption
                color="white"
                fontSize="2xl"
                sx={{ textAlign: "center" }}
              >
                {t('list.stores')}
              </TableCaption>
              <Thead>
                <Tr>
                  <Th>{t('list.name')}</Th>
                  <Th>{t('list.category')}</Th>
                  <Th>{t('list.zone')}</Th>
                  { currentLocation && <Th>{t('list.distance')}</Th>}
                  <Th>{t('list.phone')}</Th>
                  <Th>{t('list.mail')}</Th>
                  <Th>{t('list.map')}</Th>
                  <Th>{t('list.web')}</Th>
                </Tr>
              </Thead>

              <Tbody color="white">

                {data?.map((info, jota, jeta) => {

                  var distancia = Number(info.distance.toFixed(2));
                  const category = info.categories[0];

                  return (
                    <Tr
                      sx={{
                        bgColor: COLORS.dark.primary.table,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        marginBottom: "10px",
                        border: "0",
                        img: {
                          width: "2vw",
                          height: "2vw",
                          minWidth: "20px",
                          minHeight: "20px",
                          maxWidth: "30px",
                          maxHeight: "30px",
                        },
                      }}
                      key={info.id}

                    >
                      {jota == jeta.length - 1 ? <Box ref={lastBookElementRef}></Box> : null}
                      <Td>{info.title}</Td>
                      <Td>{category[0].toUpperCase() + category.substring(1)}</Td>
                      <Td>{info.location.neighborhood}</Td>
                     { currentLocation && <Td>{distancia} km</Td>}
                      <Td sx={{ display: "flex", justifyContent: "start" }}>
                        <Link href={"sms:+" + info.contact.phone}>
                          <Image src={phone} />
                        </Link>
                      </Td>
                      <Td>
                        <Link href={"mailto:" + info.contact.email}>
                          <Image src={mail} />
                        </Link>
                      </Td>
                      <Td>
                        <Link
                          href={`https://www.google.com/maps/place/${info.geometry.coordinates[0]},${info.geometry.coordinates[1]}`}
                          target="_blank"
                        >
                          <Image src={Map_fill} />
                        </Link>
                      </Td>
                      <Td>
                        <Link href={info.contact.web} target="_blank">
                          <Image src={web} />
                        </Link>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </>
    );


  }
};

export default Tabla;

