import React from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import "./fonts.css";
// import i18n (needs to be bundled ;))
import './i18n';


ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
      <App />
  </React.StrictMode>
);
