import { Box, Button, Image, Link, Switch, Text } from "@chakra-ui/react";
import BTClogo from "../../assets/constants/icons/isologo.png";
import { COLORS } from "../../assets/constants/theme/theme";
import { useTranslation } from "react-i18next";

const Header = () => {


  const { t, i18n } = useTranslation();


  const changeLanguage = () => {
    if (i18n.language === "es") {
      i18n.changeLanguage('en');
    } else {
      i18n.changeLanguage('es');
    }

  };

  return (
    <>
      <Box
        sx={{
          height: "10vh",
          width: "100vw",
          backgroundColor: COLORS.dark.primary.main,
          padding: "20px",
          display: "flex",
          justifyContent: "end",
          alignItems: "center"
        }}
      >
        <Box
          sx={{
            width: "230px",
            color: "white",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            position:"absolute",
            left:5
          }}
        >
          <Image src={BTClogo} alt="BTCLogo" boxSize="40px" marginRight={6} />
         { window.screen.width > 800 &&
          <Text
            fontWeight={500}
            fontSize="1xl"
            fontFamily="Roboto , sans-serif"
          >
            BCH Argentina
          </Text>}
        </Box>
        <Box sx={{ color: "white", display:"flex", alignItems:'center',marginRight:"30px"}}>
          <Box sx={{display:"flex", justifyContent:"space-between", alignItems:'center'}}>
            ES
            <Switch onChange={e => changeLanguage()} mr={2} ml={2} />
            EN
          </Box>
        </Box>
        <Box sx={{ color: "white" }}>

          <Link target="_blank" href="https://bcharg.com/sumate">
            <Button sx={{
              marginRight: "1vw",
              color: "#5d5e5e",
              backgroundColor: "#DFDFDF",
              fontWeight: 20,
              width:"35vw",
              maxWidth:"200px"
            }}>
              {t('buttons.add')}</Button></Link>
        </Box>
      </Box>
    </>
  );
};

export default Header;
