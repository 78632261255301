import { useState } from "react";
import Tabla from "./Tabla";

const List = ({ data, loading, setMoreData, moreData, currentLocation }) => {
  const dataSliced = data?.results?.slice(0, 12 * moreData);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Tabla
        currentLocation={currentLocation}
        loading={loading}
        data={dataSliced}
        setMoreData={setMoreData}
        moreData={moreData}
      />
    </div>
  );
};
export default List;
