import { Box } from "@chakra-ui/react";
import ResetBtn from "./Buttons/ResetBtn";
import Search from "./Buttons/Search";
import FilterBar from "./FilterBar/FilterBar";
import { setFilter, setName } from "../stores/data";
import { useDispatch } from "react-redux";
import { useState } from "react";

const BarFilter = ({ data, setLoading }) => {
  const dispatch = useDispatch();
  const [valor, setValor] = useState();
  const [busqueda, setBusqueda] = useState("");

  const resetear = () => {
    dispatch(setFilter(""));
    dispatch(setName(""));
    setValor("");
    setBusqueda("");
    setLoading(true);
  };

  if (window.screen.width < 800) {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            marginTop: "1vh",
            marginBottom: "1vh",
            marginRight: "2vw",
            marginLeft: "2vw",
            flexDirection: "column",
          }}
        >
          <Search
            busqueda={busqueda}
            setBusqueda={setBusqueda}
            setLoading={setLoading}
          />
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <FilterBar
              valor={valor}
              setValor={setValor}
              data={data}
              setLoading={setLoading}
            />
            <ResetBtn resetear={resetear} />
          </Box>
        </Box>
      </>
    );
  } else {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            marginTop: "3",
            marginBottom: "3",
            marginRight: "2vw",
            marginLeft: "2vw",
          }}
        >
          <Search
            busqueda={busqueda}
            setBusqueda={setBusqueda}
            setLoading={setLoading}
          />
          <Box sx={{ display: "flex" }}>
            <FilterBar
              valor={valor}
              setValor={setValor}
              data={data}
              setLoading={setLoading}
            />
            <ResetBtn resetear={resetear} />
          </Box>
        </Box>
      </>
    );
  }
};

export default BarFilter;
