import React from 'react'

const CIRCLE = `M 100, 100
m 75, 0
a 75,75 0 1,0 -150,0
a 75,75 0 1,0  150,0`

const pinStyle = {
    cursor: 'pointer',
    fill: '#348feb',
    stroke: 'none'
};

const LocationPin = ({ size = 30 }) => {
    return (
        <svg height={size} viewBox='0 0 24 24' style={pinStyle}>
            {/* <path d={CIRCLE} /> */}
            <circle cx="10" cy="10" r="6" />
        </svg>
    )
}

export default LocationPin