import { Button, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { useDispatch } from "react-redux";

const ResetBtn = ({ resetear }) => {

  const { t} = useTranslation();
  if (window.screen.width < 800) {
    return (
      <Button
        onClick={resetear}
        className="block"
        sx={{
          borderRadius: "20px",
          color: "#7a7a7a",

          width: "47vw",
          backgroundColor: "#DFDFDF",
          _focus: { outline: "none" },
          _hover: { borderColor: "#c4c4c4" },
        }}
        rightIcon={
          <lord-icon
            src="https://cdn.lordicon.com/akuwjdzh.json"
            trigger="click"
            target=".block"
            colors="primary:#5d5e5e"
            style={{ width: "20px" }}
          ></lord-icon>
        }
      >
        <Text
          sx={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "clip",
            width: "80%",
            fontWeight: 20,
          }}
        >
         {t('placeholder.reset')}
        </Text>
      </Button>
    );
  } else {
    return (
      <>
        <Button
          onClick={resetear}
          className="block"
          sx={{
            borderRadius: "20px",
            color: "#7a7a7a",
            height: "45px",
            width: "20vw",
            maxWidth: "200px",
            backgroundColor: "#DFDFDF",
            _focus: { outline: "none" },
            _hover: { borderColor: "#c4c4c4" },
          }}
          rightIcon={
            <lord-icon
              src="https://cdn.lordicon.com/akuwjdzh.json"
              trigger="click"
              target=".block"
              colors="primary:#5d5e5e"
              c
              style={{ width: "20px" }}
            ></lord-icon>
          }
        >
          <Text
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap;textOverflow",
              textOverflow: "clip",
              width: "10vw",
              fontWeight: 20,
            }}
          >
           {t('placeholder.reset')}
          </Text>
        </Button>
      </>
    );
  }
};
export default ResetBtn;
