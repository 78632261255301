import { Box, Button, Image, Text } from "@chakra-ui/react";
import { CloseIcon, ArrowUpIcon } from '@chakra-ui/icons'
import { motion } from "framer-motion";
import foto from "../assets/constants/icons/Captura.PNG"
const Shadow = ({ setCerrar }) => {

    const cerrando = () => {
        setCerrar(false)
    }

    return (
        <Box
            onClick={cerrando}
            sx={{
                backgroundColor: "rgba(0,0,0,0.5)",
                width: "100vw",
                height: "100vh",
                zIndex: "3",
                position: "absolute",
                top: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>

            <Box sx={{
                backgroundColor: "white",
                position: "absolute",
                top: "10%",
                borderRadius: "20px",
                width: "50vw",
                height: "50vw",
                minWidth: "300px",
                minHeight: "200px",
                maxHeight: "300px",
                display: "flex",
                flexDirection: "column", alignItems: "center", justifyItems: "center", maxWidth: "800px"
            }}>
                {window.screen.width < 800 ? <Text fontSize="12px" sx={{
                    margin: "10%",
                    textAlign: "start",
                    width: "90%",
                }}>

                    Para que la aplicación funcione correctamente,
                    active los permisos de ubicación de navegador.
                    <br />     <br />
                    1.  Vaya a la configuracion de su navegador.
                    <br /><br />
                    2.  Asegurese de que los permisos de ubicacion estan activados.
                </Text> : <Text fontSize="20px" sx={{
                    margin: "8%",
                    textAlign: "start",
                    width: "90%",

                }}>

                    Para que la aplicación funcione correctamente,
                    active los permisos de ubicación de navegador.
                    <br />     <br />
                    1.  Vaya a la configuracion de su navegador.
                    <br /><br />
                    2.  Asegurese de que los permisos de ubicacion estan activados.
                </Text>}</Box>
        </Box>
    )

}

export default Shadow;