import React, { useEffect, useState } from "react";
import { Box, Skeleton, Spinner } from "@chakra-ui/react";
import {
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  TabIndicator,
  useTab,
  useMultiStyleConfig,
  Button,
} from "@chakra-ui/react";
import BarFilter from "./BarFilters";
import MapWrapper from "./MapWrapper";
import List from "./List/List";
import { COLORS } from "../assets/constants/theme/theme";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Shadow from "./Shadow";

const TabBar = () => {
  const { t } = useTranslation();
  const [currentLocation, setCurrentLocation] = useState(null);
  const [cerrar, setCerrar] = useState(true);
  const [currentLocationStatus, setCurrentLocationStatus] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const { name, filter } = useSelector((state) => state.data);
  const [loading, setLoading] = useState(false);
  
  const [data, setData] = useState([]);
  const [moreData, setMoreData] = useState(1);

  const estilosTab = {
    color: "white",
    backgroundColor: COLORS.dark.primary.main,
    borderRadius: " 10px 10px 0px 0px",
    border: "0",
    width: "49.7%",
  };
  const estilosTab2 = {
    ...estilosTab,
    color: "#b5b5b5",
    backgroundColor: COLORS.dark.primary.mainDark,
  };

  const coordenadasPredeterminadas = [-58.4318464, -34.6098641];

  const darUbicacion = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          resolve([longitude, latitude]);
        },
        (error) => {
          reject(error);
        }
      );
    });
  };

  const fetchData = (coordinates) => {
    const [longitude, latitude] = coordinates;
    const fetchUrl = `${
      process.env.REACT_APP_API
    }/stores?q=${encodeURIComponent(name)}&category=${encodeURIComponent(
      filter
    )}&coordinates=${latitude},${longitude}`;

    axios
      .get(fetchUrl, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      })
      .then((info) => {
        setData(info.data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchData(coordenadasPredeterminadas); 
  }, []);

  useEffect(() => {
    darUbicacion()
      .then((ubicacion) => {
        setCurrentLocation(ubicacion);
        fetchData(ubicacion);
      })
      .catch((error) => {
        setCurrentLocationStatus(error.code);
      });
  }, []); 

  useEffect(() => {
    if (currentLocation || currentLocationStatus) {
      fetchData(currentLocation);
    }
  }, [name, filter ]);



  const CustomTab = React.forwardRef((props, ref) => {
  
    const tabProps = useTab({ ...props, ref });
    const isSelected = !!tabProps["aria-selected"];

    const styles = useMultiStyleConfig("Tabs", tabProps);

    return (
      <Button __css={styles.tab} {...tabProps} w="97%">
        <p style={{ fontFamily: "GontserratSemibold" }}>{tabProps.children}</p>
      </Button>
    );
  });
  const cambiarTab = (index) => {
    setTabIndex(index);
    setMoreData(1);
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "90vh",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Tabs
        isFitted
        onChange={(index) => cambiarTab(index)}
        style={{ width: "100%", height: "50%" }}
      >
        <BarFilter data={data} setLoading={setLoading}  />
        <TabList
          borderBottom={2}
          borderColor="red"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "0px",
          }}
        >
          {tabIndex == 0 ? (
            <CustomTab style={estilosTab}>{t("buttons.list")}</CustomTab>
          ) : (
            <CustomTab style={estilosTab2}>{t("buttons.list")}</CustomTab>
          )}

          {tabIndex == 1 ? (
            <CustomTab style={estilosTab}>{t("buttons.map")}</CustomTab>
          ) : (
            <CustomTab style={estilosTab2}>{t("buttons.map")}</CustomTab>
          )}
          {/* <p>Filtros</p> */}
        </TabList>
        <TabIndicator margin="0" />
        <TabPanels
          width="90vw"
          sx={{
            backgroundColor: COLORS.dark.primary.main,
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            paddingBottom: "0vh",
            marginTop: "1.5px",
          }}
        >
          <TabPanel style={{ padding: 0, paddingBottom: "10px" }}>
            <List
              setMoreData={setMoreData}
              moreData={moreData}
              data={data}
              loading={loading}
              currentLocation={currentLocation}
            />
          </TabPanel>
          <TabPanel style={{ padding: 0, paddingTop: "10px" }}>
            <MapWrapper
              data={data}
              loading={loading}
              setLoading={setLoading}
              tabIndex={tabIndex}
              currentLocation={currentLocation}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
      {!currentLocation && cerrar && currentLocationStatus == 1 && (
        <Shadow setCerrar={setCerrar} />
      )}
    </div>
  );
};

export default TabBar;
